import * as BurgerMenuEnum from '../enumerators/BurgerMenuEnum'

export default class BurgerMenuComponent {
    trigger = '[data-hc-trigger="menu"]'
    triggerSubmenu = '[data-hc-trigger="submenu"]'
    submenu = '[data-hc-id="submenu"]'
    menuEntry = '[data-hc-id="menu-entry"]'

    constructor() {
        $(this.trigger).on('click', (e) => {
            if ($('body').hasClass(BurgerMenuEnum.MENU_IS_OPEN_CLASS)) {
                this.closeMenu(e)
            } else {
                this.openMenu(e)
            }
        })

        if (window.matchMedia('(max-width: 1000px)').matches) {
            $(this.triggerSubmenu).on('click', (e) => {
                e.preventDefault()
                const currentSubmenu = $(e.currentTarget).parents(this.menuEntry).find(this.submenu)
                if (currentSubmenu.hasClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)) {
                    this.closeSubmenu(currentSubmenu.get(0))
                } else {
                    this.openSubmenu(currentSubmenu.get(0))
                }
            })
        }
    }

    openMenu(e) {
        $(e.currentTarget).addClass(BurgerMenuEnum.CROSS_ACTIVE_CLASS)
        $('body').addClass(BurgerMenuEnum.MENU_IS_OPEN_CLASS)
        const openEvent = new Event('open.hc.menu')
        document.dispatchEvent(openEvent)
    }

    closeMenu(e) {
        $(e.currentTarget).removeClass(BurgerMenuEnum.CROSS_ACTIVE_CLASS)
        $('body').removeClass(BurgerMenuEnum.MENU_IS_OPEN_CLASS)
        $(this.submenu).removeClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
        const closeEvent = new Event('close.hc.menu')
        document.dispatchEvent(closeEvent)
    }

    openSubmenu(e) {
        $(e).addClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
        $('body').addClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
    }

    closeSubmenu(e) {
        $(e).removeClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
        $('body').removeClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
    }
}
