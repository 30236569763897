import hc_lazyload from '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component'
import AutoHiddingNavAction from './actions/AutoHiddingNavAction'
import BurgerMenuComponent from './components/BurgerMenuComponent'

document.addEventListener('DOMContentLoaded', () => {
    new AutoHiddingNavAction()
    new BurgerMenuComponent()
    hc_lazyload()

    // Disable right click on all img
    $(document).on('contextmenu', 'img', (e) => false)

    // Prevent img dragging
    $('img').mousedown((e) => {
        e.preventDefault()
    })

    if ($('.home-toggle').length > 0) {
        const trigger = '[data-toggle]'
        const target = '.home'
        const toggleAttr = 'data-order'
        const toggleVal = 'on-spot'

        $(trigger).on('click', () => {
            $(target).attr(toggleAttr, (index, attr) => (attr === toggleVal ? null : toggleVal))
        })
    }

    if (!Customer.isEcomodeActive) {
        if (
            window.matchMedia('(min-width: 1000px)').matches &&
            $('[data-id="social-wall"]').length > 0
        ) {
            import(
                '@scripts/components/SocialWallComponent' /* webpackChunkName: "scripts/SocialWallComponent" */
            ).then(({ default: SocialWallComponent }) => {
                new SocialWallComponent()
            })
        }

        if ($('.strate__localisation').length > 0) {
            import(
                '@scripts/components/HomeMapComponent' /* webpackChunkName: "scripts/HomeMapComponent" */
            ).then(({ default: HomeMapComponent }) => {
                new HomeMapComponent()
            })
        }
    }

    $(document).on('wpet_ajax_build', (e, data) => {
        import(
            '@iris.interactive/handcook/public/scripts/components/tooltip/tooltip.component' /* webpackChunkName: "scripts/hc_tooltip" */
        ).then(({ HcTooltip }) => {
            new HcTooltip()
        })
    })
})
